import clsx from 'clsx';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { NextPage } from 'next';
import WidgetModel from '@/interfaces/widget';
import SEO from '@/components/head/SEO';
import Nav from '@/components/navigation/Public';
import Hero from '@/components/heros/HomeV2';
import Footer from '@/components/legal/Footer';
import SignUpForm from '@/components/forms/Newsletter';
import SectionHeader from '@/components/headers/Section';
import SharedWidgetSelect, {
  ConfirmModal as ConfirmSharedWidgetModal,
  PreviewModal as PreviewSharedWidgetModal,
} from '@/components/SharedWidgetSelect';
import { useAuth } from '@/hooks/useAuth';
import { useToast } from '@/hooks/useToast';
import BrandCarousel from '@/shadcn/components/BrandCarousel';
import layoutStyles from '@/components/Layout/styles.module.scss';
import styles from './styles.module.scss';
import LightningIcon from '../../../public/icons/lightning.svg';

// import { HomeAttributes } from 'interfaces/home';

// interface Props {
//   content: { attributes: HomeAttributes };
// }

const HomePage: NextPage = (/*{ content }*/) => {
  const router = useRouter();
  const { user } = useAuth();
  const { addToast: sendNotification } = useToast();
  const hasActiveSession = Boolean(user);
  const [selectedWidget, setSelectedWidget] = useState<WidgetModel | null>(
    null
  );
  const [previewWidget, setPreviewWidget] = useState<WidgetModel | null>(null);

  const redirectToWidgetEditor = (widget: WidgetModel) => {
    const dest = `/widgets?preview=${widget.id}`;

    if (hasActiveSession) {
      router.push(dest);
    } else {
      sendNotification({
        description: 'please sign up, to create your first widget!',
        type: 'info',
      });
      router.push(`/signup?redirect=${encodeURIComponent(dest)}`);
    }
  };

  return (
    <>
      <SEO />
      <div role="main" className={clsx(layoutStyles.main, '-bgc-gray100')}>
        <Nav />
        <Hero />

        <BrandCarousel heading="Used & Trusted By" className="-bgc-white" />

        {/* Templates */}
        <section className={clsx(styles.section, '-bgc-white')}>
          <SectionHeader Icon={<LightningIcon />} subText="Live Demos">
            Build from scratch or use{' '}
            <strong className={'-ws-nowrap'}>high-converting</strong> templates
          </SectionHeader>

          <SharedWidgetSelect
            className={styles.section__content}
            onSelect={(widget) => setSelectedWidget(widget)}
            onPreview={(widget) => setPreviewWidget(widget)}
          />
        </section>
        <SignUpForm />
        <Footer />
      </div>
      <ConfirmSharedWidgetModal
        isVisible={Boolean(selectedWidget)}
        widget={selectedWidget}
        onConfirm={(widget) => redirectToWidgetEditor(widget)}
        onClose={() => setSelectedWidget(null)}
      />
      <PreviewSharedWidgetModal
        isVisible={Boolean(previewWidget)}
        widget={previewWidget}
        onSelect={(widget) => redirectToWidgetEditor(widget)}
        onClose={() => setPreviewWidget(null)}
      />
    </>
  );
};

// export const getStaticProps: GetStaticProps = async () => {
//   const content = await import(`../../content/pages/${'home'}.md`);
//   return { props: { content: content.default } };
// };

export default HomePage;
